/* TO Modify Scroll Bar */
.custom-scrollbars__content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

/* For Chart Section */
.header_card_container {
  display: flex;
  justify-content: space-between;
  margin: 2px;
}

.dashboard {
  background-color: #0074d9;
  color: #7fdbff;
}

.dashboard.dark {
  background-color: #001f3f;
  color: #4d4d4d;
}

.dashboard.light {
  background-color: #0074d9;
  color: #7fdbff;
}

/* Use the variables in your styles */
.content {
  background-color: #001f3f;
  color: #4d4d4d;
}

/* For Notification */
.timline_heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;  
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 21px;
  font-weight: 500;
  width: 100%;
  margin: 8px;
  height: 20px;
}

.notification_Card_container {
  border-radius: 5px;
  
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.notification_row {
  justify-content: space-between;
  display: flex;
  margin-bottom: 6px;
  height: 40%;
  width: 90%;
}

.notification_row1 {
  display: flex;
  justify-content: space-between;
  width: 90%;
  height: 40%;
}
.center-cards{
  justify-content: space-between;
  display: flex;
  justify-content: center;
  align-items: center;
}


.notity_card {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  max-height: 100%;
  width: 30%;
}

.notity_card:hover {
  /* border: 1px solid; */
  /* padding: 10px; */
  box-shadow: 5px 10px 5px #d8d3d3;
  border-radius: 6px;
}

@media screen and (max-width: 768px) {
  .notity_card {
    flex-basis: 45%;
  }
}

@media screen and (max-width: 480px) {
  .notity_card {
    flex-basis: 90%;
  }
}


.toast-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 400px;
  height: 250px;
  padding: 20px;
  background: white;
  color: green;
}

.main_first {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  width: 350px;
  height: 200px;
}

.first {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  height: auto;
  width: auto;
}

.last {
  display: flex;
  justify-content: center;
  align-items: center;
}

.last_first {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.last_last {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}


/* ------for the pai charts ----------- */
.charts-big {
  display: flex;
  flex-direction: row;
  height: 100%;
  
}

.charts-two-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  
}


.first-second {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 350px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

@media screen and (max-width: 750px) {
  .first-second {
    display: flex;
    flex-direction: column;
    height: auto;

  }
}



.second-inside {
  width: 40%;
  height: 100%;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  border: 1px solid #F6F5F2;
  border-radius: 6px;
}


.default-charts {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}



.second-grap {
  display: flex;
  width: 60%;
  height: 100%;
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  border: 1px solid #F6F5F2;
  border-radius: 6px;
}


@media screen and (max-width: 750px) {
  .second-grap {
    display: flex;
    width: 100%;
    height: 100%;
    border: 1px solid #F6F5F2;
    border-radius: 6px;
  }
}
.card_width {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 750px) {
  .second-inside {
    width: 100%;
    height: 100%;
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
    border: 1px solid #F6F5F2;
    border-radius: 6px;
  }
  .card_width {
    width: 100%;
  }
}


.samewidth-second {
  height: 180px;
}

/* ------------------------ */
.first-four {
  display: flex;
  width: 100%;
  height: auto;
  overflow-x: auto;
  border: 1px solid #F6F5F2;
  border-radius: 6px;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}


.charts_first_width {
  width: 100%;
}

.nubers {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 60%;
}


@media screen and (max-width: 600px) {
  .charts-big {
    display: flex;
    flex-direction: column;
    
  }

  .card_width {
    width: 100%;
  }

  .charts_first_width {
    width: 100%;
  }

  .nubers {
    width: 100%;

  }
}


.total-user-click{
  cursor: pointer;
}


/* --------------for the holiday ------------ */
.holiday-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  padding: 0px 6px 0px 6px;
  height: 20%;
  
}
/* .holiday-main{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 6px;
  height: 60%;
  
}
.holiday-main-inc{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 6px;
  height: 100%;
  
} */
.view-button{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  text-align: center;
  height: 20%;
  
}
/* .img-cont{
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  
  width: 50%;
  height: 100%;
} */
.holiday-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 6px;
  height: 60%;
  width: 100%;
  overflow: hidden; 
  /* width: 100%; */
}

.carousel-wrapper {
  width: 100%; /* Confine carousel within this width */
  overflow: hidden; /* Prevent carousel from spilling over */
}

.carousel-content {
  display: flex;
  width: 100%; /* Ensures it doesn't expand beyond parent */
  transition: transform 0.5s ease-in-out;
}

.holiday-main-inc {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 6px;
  height: 100%;
  flex: 0 0 100%; /* Each slide takes 100% of the available width */
}

.img-cont {
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  width: 50%;
  height: 100%;
}

.prev-btn, .next-btn {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
@media screen and (min-width: 1300px) {
  .holiday-main {
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .holiday-main {
    width: 100%;
  }
}

.before:hover{
  color: #0074d9;
}