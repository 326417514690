.NewDasboard-container {
    
    height: auto;
    width: 100%;
}

.main-inside {
    display: flex;
    flex-direction: row;
    
    gap: 10px;
}

.NewDasboard-left {
    background: white;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    padding-left: 6px;
    padding-right: 6px;
    
}

.NewDasboard-right {
    position: sticky;
    top: 0;
    width: 25%;
    height: 84vh;
    max-height: 84vh;
    
}

.dash-profile {
    display: flex;
    flex-direction: row;
    height: 60px;
    
}

.dash-cards {
    display: flex;
    flex-direction: row;
    height: auto;
    gap: 10px;
    

}

.inner-div {
    
    display: flex;
    flex-direction: row;
    gap: 2px;
    width: 100%;
}

@media screen and (max-width: 775px) {
    .inner-div {
        
        display: flex;
        height: auto;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }
}






/*------------------------- cards----------------------- */

.topcards {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    gap: 2px;
    height: 140px;
    padding: 6px;
    
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.cards-header {
    display: flex;
    flex-direction: row;
    height: 20%;
    

}

.cards-second {
    height: 15%;
    

}

.cards-third {
    height: 50%;
    

}

.cards-four {
    cursor: pointer;
    align-items: center;
    text-align: center;
    gap: 6px;
    display: flex;
    flex-direction: row;
    height: 15%;
    
}

.icon-first {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 20%;
    height: 100%;
    
}

.header-card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80%;
    height: 100%;
    
}

/* ------------issue-first------------- */

.issue-cards {
    display: flex;
    flex-direction: row;
    background-color: rgba(217, 217, 217, 0.2);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 10px;
    height: 200px;
    width: 100%;
    gap: 6px;
    
}


.issue-first {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    width: 30%;
    
}

.issue-second {
    display: flex;
    gap: 6px;
    width: 70%;
    
}

.issue-card {
    flex: 1;
}

.IssueCards {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 180px;
    border-radius: 10px;
    
}

.total-numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 22px;
    font-weight: bold;
    height: 40%;
    
}

.lable-conter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    
}

.view-more {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    
}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    
}

.view-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 80%;
    height: auto;
    
    color: blue;
    background: white;
    text-decoration: underline;

}

.container-between {
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 10px;
  }

@media screen and (max-width: 775px) {

    .issue-cards {
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .issue-first {
            width: 100%;
            height: auto;

    }
    .issue-second{
        width: 100%;
    }
   
    .main-inside {
        display: flex;
        flex-direction: column;
        
        gap: 10px;
    }
    .NewDasboard-left {
        background: white;
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        padding-left: 6px;
        padding-right: 6px;
        
    }
    
    .NewDasboard-right {
        position: sticky;
        top: 0;
        width: 100%;
        height: 200px;
        
    }
    .response{
        margin-bottom: 12px;
    }

}
@media screen and (max-width: 993px) {
.response{
    margin-bottom: 12px;
}
}
@media screen and (max-width: 650px) {


.container-between {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
}



/* ----------------------------------------------------- */