.Dash_card {
  /* display: flex;
    flex-direction: column; */
  flex-wrap: wrap;
  background: #ffffff;
  border-radius: 6px;
  /* overflow: hidden; */
  box-shadow: 0px 1px 1px 2px rgba(201, 198, 198, 0.2);
  margin: 20px;
  width: max(200px, 30%, 50vh);
  height: 13vh;
  position: relative;
}

.card-content1 {
  color: white;
  text-align: center;
  padding: 16px;
  font-size: 28px;
  align-items: center;
  /* display: inline-block; */
  justify-content: center;
}

.card-content {
  color: white;
  text-align: center;
  padding: 1em;
  font-size: 28px;
  align-items: center;
  /* display: inline-block; */
  justify-content: center;
  flex-wrap: wrap;
}

.card-title {
  text-align: right;
  font-size: 16px;
  margin: 0;
  margin-bottom: 8px;
  margin-right: 0px;
}

.card-description {
  text-align: right;
  color: rgb(149, 144, 144);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.Dash_card1 {
  max-width: 90%;
  height: 69px;
  position: absolute;
  border-radius: 12px;
  color: white;
  left: 20%;
  top: -35%;
  transform: translateX(-50%);
}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 767px) {
  .Dash_card1,
  .card-content {
    flex: 0 0 calc(100% - 20px);
  }
}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 767px) {
  .Dash_card1 {
    max-width: 100%;
    height: auto;
    position: static;
    border-radius: 0;
    transform: none;

    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
