body {
  font-family: "Helvetica Neue", Helvetica, Arial;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #3b3b3b;
  -webkit-font-smoothing: antialiased;
  font-smooth: initial;
  // background: #fffafa;
  .center-text-cor {
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    align-content: center;
  }
  .crr-text-style:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .hover-card-correction {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: #80c4e9;
    max-height: 120px;
    min-height: 120px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .hover-card-correction:hover {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
    background-color: #5baed7;
    transform: scale(1.05); /* Slightly enlarge */
  }

  .hover-card-claim {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    padding: 20px;
    border-radius: 6px;
    min-height: 100px;
    max-height: 100px;
  }
  .hover-card-claim:hover {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 15px;
    transform: scale(1.05); /* Slightly enlarge */
  }
  .second-off {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .action {
    width: 10%;
    height: 100%;
  }
  .crr-table {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: #5baed7;
    color: white;
    width: 100%;
    border: 2px solid yellow;
  }
  .cells {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    color: black;
    border-right: 1px solid #ddd;
    // flex: 1;
    text-align: center;
  }
  .cells:last-child {
    border-right: none;
  }
  .second-off:hover {
    overflow-x: scroll;
    /* Show scrollbar on hover */
  }

  /* Scrollbar styling */
  .second-off::-webkit-scrollbar {
    height: 8px;
    /* Height of horizontal scrollbar */
  }

  .second-off::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track color */
    border-radius: 5px;
    /* Optional: Rounded corners */
  }

  .second-off::-webkit-scrollbar-thumb {
    background: #888;
    /* Thumb color */
    border-radius: 5px;
    /* Optional: Rounded corners */
  }

  .second-off::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Thumb hover color */
  }
  .data-correction {
    display: flex;
    flex-direction: row;
    height: auto;
    border-bottom: 2px solid #f5f5f6;
    color: #3b3b3b;
  }

  @media screen and (max-width: 580px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.wrapper {
  margin: 0 auto;
  padding: 30px;
  max-width: 100%;
}

.table1 {
  margin: 0 0 40px 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;

  @media screen and (max-width: 580px) {
    display: block;
  }
}

.row1 {
  display: table-row;
  background: #f6f6f6;

  &:nth-of-type(odd) {
    background: #e9e9e9;
  }

  &.header {
    font-weight: 900;
    color: #ffffff;
  }

  &.green {
    background: rgb(0, 128, 255);
  }

  @media screen and (max-width: 580px) {
    padding: 14px 0 7px;
    display: block;

    &.header {
      padding: 0;
      height: 6px;

      .cell {
        display: none;
      }
    }

    .cell {
      margin-bottom: 10px;

      &:before {
        margin-bottom: 3px;
        content: attr(data-title);
        min-width: 98px;
        font-size: 10px;
        line-height: 10px;
        font-weight: bold;
        text-transform: uppercase;
        color: #969696;
        display: block;
      }
    }
  }
}

.cell {
  padding: 6px 12px;
  display: table-cell;

  @media screen and (max-width: 580px) {
    padding: 2px 16px;
    display: block;
  }
}

// for the correcion dashboard
.table_correction {
  /* margin-top: 2px; */
  overflow-x: auto;
  border-collapse: collapse;
  width: 100%;
  border: 2px solid red;
}

.table_ctd td {
  color: white;
  border: 2px solid white;
  background: #4379f2;
  border-bottom: 2px solid #3b3b3b;
  padding: 4px;
  text-align: center;
}
.table_correction td {
  border: 2px solid #f5f5f6;
  text-align: center;
}
.stylecard-cor{
  border: 2px solid #f5f5f6;
  border-radius: 6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}