.re-card-center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    height: auto;
    width: 100%;
    gap: 4px;
    padding: 10px;
}


.dashboard-carts {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 90%;
    gap: 6px;
    border: 2px solid #F6F5F2;
    padding: 4px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashcart-first {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 40px;
    border-radius: 50%;
    
}

.dashcart-second {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 25%;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    
}

.dashcart-third {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    height: 25%;
    width: 100%;
    font-weight: 400;
    
}

.dashcart-last {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    height: 25%;
    width: 100%;
    font-size: 10px;
    color: #0052cc;
    
}


@media (min-width: 1450px) and (max-width: 2000px) {
    .dashcart-second {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 25%;
        font-size: 18px;
        font-weight: bold;
        width: 100%;
        
    }

    .dashcart-third {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        height: 25%;
        width: 100%;
        font-weight: 400;
        
    }
    
    .dashcart-last {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        height: 25%;
        width: 100%;
        font-size: 12px;
        color: #0052cc;
        
    }


}

@media (min-width: 2000px) and (max-width: 3000px) {
    .dashcart-second {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 25%;
        font-size: 22px;
        font-weight: bold;
        width: 100%;
        
    }

    .dashcart-third {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        height: 25%;
        width: 100%;
        font-weight: 400;
        
    }
    
    .dashcart-last {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        height: 25%;
        width: 100%;
        font-size: 14px;
        color: #0052cc;
        
    }



}