//
// _dropdown.scss
//

.dropdown-menu {
  box-shadow: $box-shadow-lg;
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  margin: 0;
  position: absolute;
  z-index: 1000;

  &.show {
    top: 100% !important;
  }
}
.dropdown-position{
  position: absolute;
}

.hover-header {
  position: relative;
  text-decoration: none; /* Start without underline */
}

@media screen and (max-width: 600px) {
  .dropdown-menu {
    box-shadow: $box-shadow-lg;
    animation-name: DropDownSlide;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    margin: 0;
    position: absolute;
    z-index: 1000;
  
    &.show {
      top: 8% !important;
    }
  }
}

.hover-header::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px; /* Position the underline slightly below the text */
  width: 0;
  height: 2px; /* Thickness of the underline */
  background-color: currentColor; /* Matches the text color */
  transition: width 0.3s ease;
  color:blue;
}

.hover-header:hover::after {
  color:blue;
  width: 100%; /* Expands the underline smoothly */
}

.hover-header:hover{
  color:blue;
}

@media (max-width: 600px) {
  .dropdown-menu.show {
    top: 10% !important;
  }
  .dropdown-position{
    position: absolute;
    width: 20% !important;
  }
}

.dropdown-menu-end[style] {
  left: auto !important;
  right: 0 !important;
}

.dropdown-menu[data-popper-placement^="right"],
.dropdown-menu[data-popper-placement^="top"],
.dropdown-menu[data-popper-placement^="left"] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }
  0% {
    transform: translateY(10px);
  }
}

@media (min-width: 600px) {
  .dropdown-menu-lg {
    width: 320px;
  }

  .dropdown-menu-md {
    width: 240px;
  }
}

.dropdown-divider {
  border-top-color: $border-color;
}

// Dropdown Mega Menu

.dropdown-mega {
  position: static !important;
}

.dropdown-megamenu[style] {
  padding: 20px;
  left: 20px !important;
  right: 20px !important;
}

// Dropdown size

.dropdown-mega-menu-xl {
  width: 40rem;
}

.dropdown-mega-menu-lg {
  width: 26rem;
}
//ye wala csss me add kar dijega 

.same-socket::-webkit-scrollbar {
  display: none;
}