// .table-responsive {

//     width: 100%;
//     height: 400px;
//     overflow: hidden;

//  }
//  .topheader {
//     table-layout: auto;
//     font-size: 12px;
//     font-weight: bold;
//     table-layout: auto;
//     width: 100%;
//     overflow: auto;

//  }
// .button {
//     border-radius: 20px;
// }
th {
  position: sticky;
  top: 0;
  background-color: #f8f9fa;
  padding: 6px 15px;
}

td {
  padding: 4px 15px;
  border: 2px;
  background-color: white;
  text-align: left;
}
// //    }
// // tr {
// //     width: 100px;
// //     height: 50px;

// // }
.delete-btn:hover {
  color: red;
}
.arrow {
  font-size: 13px;
  cursor: pointer;
}
.arrow:hover {
  color: blue;
}
