//
// Chat.scss
//

.chat-leftsidebar {
  @media (min-width: 992px) {
    min-width: 260px;
  }

  @media (min-width: 1200px) {
    min-width: 380px;
  }

  .chat-leftsidebar-nav {
    .nav {
      background-color: $card-bg;
    }

    .tab-content {
      min-height: 488px;
    }
  }
}

.chat-noti-dropdown {
  &.active {
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $danger;
      border-radius: 50%;
      right: 0;
    }
  }

  .btn {
    padding: 6px;
    box-shadow: none;
    font-size: 20px;
  }
}

.form-custom {
  font-size: 12px;
  border: none;
  border-bottom: 2px solid black;
  outline: none;
  padding-left: 0px;
  // padding-top: 4px;     
  // padding-right: 8px;   
  // padding-bottom: 4px; 
  transition: border-color 0.3s ease;
}

.form-custom:focus {
  border-bottom-color: blue;
  outline: none;
}





.first-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  // position: relative;
  text-align: center;
  align-items: center;
  // box-shadow: 4px 0px 5px -2px rgba(245,240,245,1);
}

.second-div {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}

.first-content {
  // display: flex;
  justify-content: center;
  // position: absolute;E
  align-items: center;
  text-align: center;
  top: 8px;
  left: 16px;
  height:10%;
  width: 100%;
}


@media (max-width: 950px) {
  .first-div {
    display: none;
    
  }

  .second-div {
    width: 100%;
  }
}

.chat-search-box {
  .form-control {
    border: 0;
  }
}

.chat-list {
  margin: 0;

  li {
    &.active {
      a {
        background-color: $card-bg;
        border-color: transparent;
        box-shadow: $box-shadow;
      }
    }

    a {
      display: block;
      padding: 14px 16px;
      color: $gray-600;
      transition: all 0.4s;
      border-top: 1px solid $border-color;
      border-radius: 4px;

      &:hover {
        background-color: $card-bg;
        border-color: transparent;
        box-shadow: $box-shadow;
      }
    }
  }
}

.user-chat-nav {
  .dropdown {
    .nav-btn {
      height: 40px;
      width: 40px;
      line-height: 40px;
      box-shadow: none;
      padding: 0;
      font-size: 16px;
      background-color: $light;
      border-radius: 50%;
    }

    .dropdown-menu {
      box-shadow: $box-shadow;
      border: 1px solid $border-color;
    }
  }
}

.chat-conversation {
  li {
    clear: both;
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;

    .title {
      background-color: $card-bg;
      position: relative;
      z-index: 1;
      padding: 6px 24px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .conversation-list {
    margin-bottom: 24px;
    display: inline-block;
    position: relative;

    .ctext-wrap {
      padding: 12px 24px;
      background-color: rgba($primary, 0.1);
      border-radius: 8px 8px 8px 0px;
      overflow: hidden;

      .conversation-name {
        font-weight: $font-weight-semibold;
        color: $primary;
        margin-bottom: 4px;
      }
    }

    .dropdown {
      float: right;

      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;

        @media (max-width: 575.98px) {
          display: none;
        }
      }

      .dropdown-menu {
        box-shadow: $box-shadow;
        border: 1px solid $border-color;
      }
    }

    .chat-time {
      font-size: 12px;
    }
  }

  .right {
    .conversation-list {
      float: right;

      .ctext-wrap {
        background-color: $light;
        text-align: right;
        border-radius: 8px 8px 0px 8px;
      }

      .dropdown {
        float: left;
      }

      &.last-chat {
        .conversation-list {
          &:before {
            right: 0;
            left: auto;
          }
        }
      }
    }
  }

  .last-chat {
    .conversation-list {
      &:before {
        content: "\F0009";
        font-family: "Material Design Icons";
        position: absolute;
        color: $primary;
        right: 0;
        bottom: 0;
        font-size: 16px;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }
  }
}

.chat-input-section {
  border-top: 1px solid $border-color;
}

.chat-input {
  border-radius: 30px;
  background-color: $light !important;
  border-color: $light !important;
  padding-right: 120px;
}

.chat-input-links {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  li {
    a {
      font-size: 16px;
      line-height: 36px;
      padding: 0px 4px;
      display: inline-block;
    }
  }
}

.chat-send {
  @media (max-width: 575.98px) {
    min-width: auto;
  }
}












// -------------------------------------------
/* src/LoginPage.css */
* {
  margin: 0;
  padding: 0;
}

.container-ess {
  display: flex;
  height: 90vh;
  background-color: #f5f6fa;
}

.left-panel, .right-panel {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left-panel {
  height: 100%;
  flex: 1;
  background-color: #fff;
  border-right: 1px solid #dcdde1;
  text-align: center;
}

.ess {
  font-size: 24px;
  color: #4b7bec;
  // margin-bottom: 2rem;
}

.ESS {
  color: #444;
}


.rig-text {
  color: #888;
  font-size: 14px;
  margin-bottom: 1rem;
}

.right-panel {
  flex: 1.5;
  background-color: #4b7bec;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  margin-right: 20px;
}

.right-txt{
  color: rgb(66, 60, 60);
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 1rem;
}

.right-span{
  color: #dcdde1;
}

.rig-text{
  font-size: 14px;
  color: #dcdde1;
  margin-bottom: 2rem;
}

.chart-placeholder {
  background-color: #fff;
  // padding: 1rem;
  border-radius: 8px;
  width: 80%;
  height: 300px;
  max-height: 300px;
}

.cell-txt{
  color: #4b7bec;
  font-weight: bold;
  margin-bottom: 1rem;
}

.bar-chart {
  background-color: #dfe4ea;
  height: 200px;
  border-radius: 5px;
}

@media (max-width: 750px) {
  .right-panel {
    display: none;
    
  }

  .second-div {
    width: 100%;
  }
}