.header-container {
  // background: #ffffff;
  // box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // height: 65px;
  width: 100%;
  margin: auto;
  p {
    margin: 0;
  }
  nav {
    display: flex;
    justify-content: center;
    align-items: center;

    .dropdown {
      position: relative;
      padding: 0 1rem;
      cursor: pointer;
      p,
      a {
        color: black;

        &:hover {
          color: royalblue;
        }
      }

      #active {
        display: flex;
      }
    }
    .dropdownMenu {
      position: absolute;
      //
      background: #ffffff;
      border-radius: 0.25rem;

      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
      animation-name: DropDownSlide;
      animation-duration: 0.3s;
      animation-fill-mode: both;
      //
      z-index: 10;
      top: 42px;
      left: 29px;
      width: 170px;
      font-size: 12.5px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      a {
        padding: 5px;
        &:hover {
          color: #4458b8;
        }
      }
      display: none;
    }
  }
}

.hamburgerButton {
  color: #555b6d;
  background: transparent;
  font-size: 16px;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  &:hover {
    background: rgba($color: #555b6d, $alpha: 0.1);
  }

  // position: fixed;
  // top: 14px;
  // left: 258px;
  // z-index: 100000;

  // @media (max-width: 991.98px) {
  //   display: none;
  //   //   left: 15px;
  // }
}

.side_bar {
  flex: 1;

  @media (max-width: 1200px) {
    display: none;
  }
}
.a {
  flex: 1;
}
//
//
//
#page-topbar_horizontal {
  background: #2a3042;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  width: 100%;

  .header-container {
    background: transparent;
    .dropdown {
      a,
      p {
        color: white;
        &:hover {
          color: royalblue;
        }
      }
      .dropdownMenu {
        p,
        a {
          color: black;

          &:hover {
            color: royalblue;
          }
        }
      }
    }
  }
}

//
#page-topbar {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  margin-left: 0;
  // 250px
}
.main-content {
  margin-left: 0;
}

.margin-left {
  margin-left: 233px;
}
// .margin-left-20rem {
//   margin-left: 20rem;
// }
// .margin-left-15rem {
//   margin-left: 15rem;
// }

// To Continuos Rotation
.rotate {
  animation: rotation 2s infinite linear;
  color: black;
  &:hover {
    color: royalblue;
  }
}

.turn_hover {
  color: black;
  &:hover {
    color: royalblue;
  }
}
#min_outlet {
  width: 84vw;
  @media (max-width: 1200px) {
    width: 100vw;
  }
}
#max_outlet {
  width: 100vw;
}
