.main_container {
  width: auto;
  height: 150px;
  display: flex;
  flex-direction: column;
  cursor: "pointer"; 
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0px 6px 0px 6px;
  border-radius: 4px;
  cursor: pointer;
}





.caard-inner {
  display: flex;
  justify-content: space-between;
}

.card_titl {
  color: #ffffff;
  opacity: 0.9;
  font-size: 18px;
  font-weight: 450;
}

.card_value {
  font-size: 22px;
  font-weight: 450;
  color: #f9f9f9;
}
.caard-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* size: 100px; */
  /* position: absolute; */
  color: #d50000;
  margin-left: auto;
  margin-top: -30px;
  /* margin-bottom: 50px; */
}

/* End Main */

/* Medium <= 992px */

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      "header"
      "main";
  }
}

/* Small <= 768px */

@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */
@media screen and (max-width: 576px) {
  .main_container {
    border-radius: 20px;
  }


  .card_titl {
    font-size: 14.5px;
    margin: 0;
  }
  .card_value {
    font-size: 18px;
    margin: 0;
    font-weight: 450;
  }
}



/* ------------------------------- */
.att-carts{
  display: flex;
  align-items: center;
  height: 25%;
  font-weight: bold;
   
}
.att-cart-second{
  display: flex;
  align-items: center;
  height: 41%;
  font-size: 24px;
  font-weight: bold;
  
}
.att-cart-third{
  display: flex;
  height: 33%;
   
   gap: 4px;
}

.card-shadow-bottom{
  box-shadow: -1px 8px 5px -9px rgba(0,0,0,0.27);
}
.card-shadow-top{
  box-shadow: -1px -8px 5px -9px rgba(0,0,0,0.27);
}
.card-shadow-left{
  box-shadow: -8px 0px 5px -9px rgba(0,0,0,0.27);
}
.card-shadow-right{
  box-shadow: 8px 0px 5px -9px rgba(0,0,0,0.27);
}
.all-shadow{
  box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.08);
}


.nubers-first{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40%;
  
}
.nubers-second{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  
  gap: 10px;
}
.nubers-present{
  display: flex;
  flex-direction: row;
  gap: 6px;
  height: 49%;
  
}

.present-left{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  padding-right: 6px;
  width: 50%;
  height: 100%;
  
}
.present-right{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  padding-right: 6px;
  width: 50%;
  height: 100%;
  
}

.first-graph{
  display: flex;
  flex-direction: column;
  
}



.top-first{
  display: flex;
  gap: 4px;
  height: 40%;
  flex-direction: row;
  
}
.icon-dashboard{
  width: 50%;
}
.second-icon{
  width: 50%;
}

.custom-modal .modal-dialog {
  max-width: 70%; /* Adjust the width as needed */
  width: 90%; /* Ensure it is responsive */
}

@media (max-width: 768px) {
  .custom-modal .modal-dialog {
    max-width: 95%; /* Adjust for smaller screens */
  }
}

@media (min-width: 992px) {
  .custom-modal .modal-dialog {
    max-width: 70%; /* Larger screens can have a bigger width */
  }
}
